// COLORS

$black: #000000;
$white: #FFFFFF;

$grey: #E9E9E9;
$grey-light: #F5F5F5;
$grey-dark: #4B4B4B;

$blue: #054FD6;
$blue-light: #215ED8;
$blue-dark: #00004F;

$yellow: #FFC229;
$green: #45CC63;


// TEXT

$font-main: 'Resist Sans Display';
$font-second: 'Resist Sans Text';

$weight-medium: 500;
$weight-regular: 400;
$weight-light: 300;

// MEDIA

$media-desktop: 1920px;
$media-desktop-small: 1600px;
$media-large: 1023px;
$media-medium: 767px;
$media-small: 479px;
$media-extra-small: 360px;
