@font-face {
  font-family: $font-second;
  src: url('ResistSansText-Regular.eot');
  src: url('ResistSansText-Regular.eot?#iefix') format('embedded-opentype'),
      url('ResistSansText-Regular.woff2') format('woff2'),
      url('ResistSansText-Regular.woff') format('woff'),
      url('ResistSansText-Regular.ttf') format('truetype');
}
@font-face {
  font-family: $font-second;
  src: url('ResistSansText-Light.eot');
  src: url('ResistSansText-Light.eot?#iefix') format('embedded-opentype'),
      url('ResistSansText-Light.woff2') format('woff2'),
      url('ResistSansText-Light.woff') format('woff'),
      url('ResistSansText-Light.ttf') format('truetype');
  font-weight: $weight-light;
}
@font-face {
  font-family: $font-second;
  src: url('ResistSansText-Medium.eot');
  src: url('ResistSansText-Medium.eot?#iefix') format('embedded-opentype'),
      url('ResistSansText-Medium.woff2') format('woff2'),
      url('ResistSansText-Medium.woff') format('woff'),
      url('ResistSansText-Medium.ttf') format('truetype');
  font-weight: $weight-medium;
}
