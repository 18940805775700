.button {
  border: none;
  border-radius: 12px;
  font-family: $font-second;
  line-height: 120%;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .5s;

  @media (max-width: $media-large) {
    height: 52px;
  }

  &-arrow {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $blue;
    background: $white;
    cursor: pointer;
    transition: .4s;

    &--left {
      transform: rotateZ(180deg);
      right: auto;
      left: -30px;
    }

    &:hover {
      background: $blue;
      border-color: $white;

      path {
        stroke: $white;
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    span {
      padding-top: 2px;
    }
  }

  &.disabled {
    cursor: auto;
    opacity: 50%;

    &:hover {
      background: $yellow;
    }
  }

  svg {
    margin-left: 6px;

    &.arrow {
      width: 16px;
      height: 10px;
    }
  }

  &-main {
    padding: 0 30px;
    background: $yellow;
    color: $black;
    font-size: 18px;
    line-height: 120%;
    font-weight: $weight-medium;

    &:hover {
      background: darken($yellow, 10%);
    }

    &--short {
      height: 50px;
    }
  }

  &-secondary {
    height: 47px;
    padding: 0 30px;
    background: $grey;
    color: $blue;
    font-size: 16px;

    &:hover {
      background: darken($grey, 5%);
    }
  }

  &-outline {
    height: 50px;
    padding: 0 30px;
    border: 1px solid $black;
    font-size: 16px;
    line-height: 120%;
    color: $black;
    background: transparent;

    &:hover {
      background: darken($grey, 2%);
    }
  }

  &-back {
    height: 35px;
    padding: 0 20px;
    border: 1px solid $blue;
    border-radius: 50px;
    transition: .5s;
    cursor: pointer;

    &:hover {
      background: $blue;

      * {
        color: $white;
      }

      svg {
        path {
          stroke: $white;
        }
      }
    }

    svg {
      width: 12px;
      height: 7.5px;
    }

    span {
      padding-top: 2px;
    }
  }
}

.slider {
  width: 750px;

  @media (max-width: $media-desktop-small) {
    width: 420px;
    height: 362px;
  }

  @media (max-width: $media-large) {
    position: relative;
    display: flex;
    align-items: center;
  }

  &-card {
    width: 440px;
    min-height: 586px;
    padding: 20px;
    border-radius: 60px;
    background: rgba(0, 0, 0, 0.6);
    border: 2px solid #4B4B4B;
    backdrop-filter: blur(5px);
    cursor: pointer;
    //transition: .5s;

    @media (max-width: $media-desktop-small) {
      width: 220px;
      min-height: 302px;
      border-radius: 40px;

      img {
        max-height: 180px !important;
        border-radius: 20px !important;
      }
    }

    p {
      //@media (max-width: $media-desktop-small) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      //}
    }

    &__title {
      @media (max-width: $media-desktop-small) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    img {
      border-radius: 40px;
      max-height: 420px;
      width: 100%;
      //transition: .5s;
    }

    &--small {
      width: 290px;
      min-height: 396px;

      @media (max-width: $media-desktop-small) {
        width: 182px;
        min-height: 265px;

        img {
          max-height: 140px !important;
        }
      }

      img {
        max-height: 250px;
      }
    }
  }

  &-control {
    @media (max-width: $media-desktop-small) {
      justify-content: center;
    }

    @media (max-width: $media-large) {
      position: absolute;
      width: 100%;
      padding: 0 !important;
      margin: 0 !important;
      justify-content: normal;

      &__progress-bar {
        display: none;
      }

      &__arrow {
        position: absolute;

        &:first-child {
          left: -60px;
        }

        &:nth-child(2) {
          right: -60px;
        }
      }
    }

    &__arrow {
      width: 40px;
      height: 40px;
      background: $white;
      border-radius: 10px;
      cursor: pointer;
      transition: .4s;

      &:hover {
        background: darken($white, 15%);
      }
    }

    &__progress-bar {
      width: 270px;
      height: 4px;
      background: rgba(255, 255, 255, 0.4);

      @media (max-width: $media-desktop-small) {
        width: 220px;
      }

      span {
        background: #FFFFFF;
        border-radius: 10px;
        height: 100%;
        display: block;
        transition: .4s;
      }
    }
  }

  .swiper {
    @media (max-width: $media-large) {
      //display: flex;
      //align-items: center;
    }
    &-wrapper {
      height: 586px;

      @media (max-width: $media-desktop-small) {
        height: 310px;
      }
    }
    //swiper-slide-next
    &-slide {
      &-active {
        display: grid;
        align-items: center;
      }
      &-next {
        justify-content: flex-end !important;
      }
      &:not(&-active) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.tariff {
  &-card {
    position: relative;
    min-width: 367px;
    max-width: 367px;
    min-height: 698px;
    max-height: 698px;

    background: $grey-light;
    border-radius: 30px;
    overflow: hidden;

    @media (max-width: $media-large) {
      min-width: 330px;
      max-width: 330px;
      min-height: 660px;
      max-height: 660px;

      h3 {
        margin-bottom: 20px;
      }

      &__phone {
        h3 {
          font-size: 20px !important;
        }
        &-info {
          p {
            font-size: 16px !important;
          }
        }
      }
    }

    @media (max-width: $media-large) {
      h3 {
        @include h4($black);
        margin-bottom: 20px;
      }

      &__advantages {
        &__title {
          //font-size: 16px !important;
        }
      }

      &__available {
        p {
          @include b7();
        }
      }

      .price {
        font-size: 40px !important;
      }

      .currency {
        @include b7();
      }
    }

    &.game-mode {
      background: $blue;

      h3, p {
        color: $white;
      }

      .tariff-card__available {
        background: rgba(255, 255, 255, 0.1) !important;
      }

      .tariff-card__advantages {
        border-bottom-color: rgba(255, 255, 255, 0.1) !important;
      }

      .button-secondary {
        background: rgba(255, 255, 255, 0.1) !important;
        color: $white;

        &:hover {
          border: 1px solid $white;
        }
      }
    }

    &.modal {
      min-width: 700px;
      max-width: 700px;

      @media (max-width: $media-medium) {
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        border-radius: 0;
        overflow: auto;

        .tariff-card {
        }

        .modal-footer {
          flex-direction: column;
        }
      }

      .tariff-card {
        &__info {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;

          h3 {
            flex-basis: 73%;
          }
        }

        &__phone-info {
          flex-basis: 100%;

          img {
            right: 0 !important;
          }
        }

        &__advantages {
          flex-basis: 100%;
          //margin-bottom: 0;

          &.mb-6 {
            margin-bottom: 15px;
          }

          &-wrapper {
            display: flex;
            flex-wrap: wrap;
            flex-basis: 100%;
            gap: 15px;
          }
        }

        &__available {
          margin-bottom: 5px;
        }
      }

      h3 {
        min-height: auto;
        margin-bottom: 10px;
      }

      img {
        right: 70px;
      }

      .modal {
        &-description {
          width: 100%;
          flex: 1;
          margin-top: 15px;
          word-break: break-all;

          ul {
            padding-left: 30px;
            margin-bottom: 20px;

            li {
              list-style: disc;
              font-family: $font-main;
              @include b6();

              margin-bottom: 10px;
            }
          }

          p {
            margin-bottom: 20px;
          }
        }

        &-close {
          position: absolute;
          top: 30px;
          right: 30px;
          cursor: pointer;
          z-index: 3;
        }

        &-footer {
          display: flex;
          justify-content: space-between;

          .button {
            min-width: auto;
          }
        }
      }
    }

    .game-mode {
      &__texture {
        right: 0;
        width: 100%;
        height: 100%;
      }
    }

    h3 {
      width: 80%;
      min-height: 58px;
    }

    img {
      width: 50px;
      position: absolute;
      top: 0;
      right: 30px;
    }

    .price {
      font-size: 50px;
      line-height: 120%;
      letter-spacing: -0.03em;

      &.promo {
        color: $green;
      }

      &-old {
        position: relative;
        font-size: 21px;
        line-height: 130%;

        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          position: absolute;
          content: '';
          width: 110%;
          height: 2px;
          background: black;
          margin-top: -4px;
        }
      }
    }

    &__advantages {
      padding: 12px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__available {
      padding: 10px 20px;
      background: $grey;
      border-radius: 100px;
    }

    &__kion {
      .tariff-card {
        &__available {
          flex-direction: column;
          align-items: flex-start;
          border-radius: 0;
          gap: 6px;
          background: transparent;
          padding: 0 0 20px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          img {
            position: relative;
            right: 0;
            width: 70px;
          }
        }
      }
    }

    &__smotreshka {
      .tariff-card {
        &__advantages {
          //margin-bottom: 12px;
          img {
            position: relative;
            left: 0;
            width: 24px;
          }
        }
      }
    }

    &__phone {
      h3 {
        font-size: 24px;
      }
      img {
        position: relative;
        width: 24px;
        height: 24px;
        right: 0;
        top: 0;
      }

      &-info {
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.tabs {
  border-bottom: 2px solid $grey;

  @media (max-width: $media-medium) {
    flex-wrap: wrap;
    justify-content: flex-start !important;
    gap: 5px;
    border-bottom: none;
    margin-bottom: 30px;

    &__tab {
      height: 33px;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $grey-dark;
      padding: 0 16px !important;
      border: 1px solid rgba(75, 75, 75, 0.4);
      border-radius: 50px;

      &.active {
        border: 1px solid $blue !important;
      }

      span {
        line-height: 1;
        padding-top: 1px;
      }
    }
  }

  &__tab {
    margin-bottom: -2px;
    cursor: pointer;

    span {
      color: $grey-dark;
      opacity: 0.4;
    }

    &.active {
      span {
        color: $blue;
        opacity: 1;
      }

      border-bottom: 2px solid $yellow;
    }
  }

  &-box {
    flex-wrap: wrap;
    &__tab {
      height: 38px;
      padding: 8px 14px;
      border: 1px solid $blue-dark;
      border-radius: 10px;
      cursor: pointer;

      &.active {
        background: #00004F;
        border: none;
        color: $white;
      }
    }
  }
}

.post {
  &-wrapper {
    row-gap: 30px;
    column-gap: 40px;
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: minmax(400px, 460px);

    @media (max-width: $media-desktop-small) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $media-medium) {
      grid-template-columns: 1fr;
    }
  }

  &-card {
    box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    height: 461px;
    cursor: pointer;

    @media (max-width: $media-desktop-small) {
      .img {
        min-height: 320px !important;
      }

      &__wrapper {
        //flex-basis: calc((100% - 40px) / 2) !important;
      }

      h3 {
        width: 345px !important;
      }
    }

    @media (max-width: $media-large) {
      h3 {
        width: 245px !important;
      }
    }

    @media (max-width: $media-medium) {
      height: 440px;

      .img {
        min-height: 300px !important;
      }
    }

    @media (max-width: $media-small) {
      height: 400px;

      .img {
        min-height: 242px !important;
      }
    }

    &:hover {
      .img {
        transform: scale(1.1);
      }
    }

    h3 {
      width: 245px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      line-height: 22px !important;
    }

    .img {
      width: 100%;
      min-height: 300px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transition: .6s;

      &-wrapper {
        border-radius: 20px;
        overflow: hidden;
      }
    }

    &__wrapper {
      //flex-basis: calc((100% - 120px) / 4);
      min-height: 400px;

      .react-reveal:not(h1) {
        height: 100%;
      }
      &.full-card {
        @include fillCard();

        &--start {
          grid-column-start: 1;
          grid-column-end: 3;
        }

        &--end {
          grid-column-start: 3;
          grid-column-end: 5;
        }
      }
    }
  }
}

.checkbox {
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked+label::before {
      border-color: $blue;
      background-color: $blue;
      background-image: url('./../img/Check.svg');
    }
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid $blue;
      border-radius: 5px;
      margin-right: 0.5em;
      margin-top: -4px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 90%;
    }
  }
}

.radio {
  padding-left: 35px;
  width: auto;
  cursor: pointer;

  label {
    position: relative;
    min-height: 25px;
    display: flex;
    align-items: center;
    flex: 1;
    cursor: pointer;

    @include b6();

    color: $blue-dark;

    &::before {
      content: "";
      position: absolute;
      left: -35px;
      width: 25px;
      height: 25px;
      border: 2px solid $blue-dark;
      background-color: #ffffff;
      box-sizing: border-box;
    }
  }
  input {
    z-index: -1;
    opacity: 0;
    position: absolute;

    &:checked + label::after {
      content: "";
      position: absolute;
      left: -30px;
      width: 15px;
      height: 15px;
      background-color: $blue-dark;
      border-radius: 100%;
      box-sizing: border-box;
    }

    &:checked + label:before,
    &:not(:checked) + label:before {
      border-radius: 100%;
    }
  }
}

.input {

  @media (max-width: $media-medium) {
    &-text {
      //height: 30px;
      .prepend {
        padding-bottom: 15px;
        &-wrapper {
          svg {
            padding-bottom: 6px;
          }
        }
      }
    }

    input {
      -webkit-appearance: none;
      -webkit-border-radius: 0px;
      padding-bottom: 0px;
    }
  }
  &-text {
    width: 100%;

    .prepend {
      display: flex;
      align-items: center;
      height: 100%;

      &-wrapper {
        position: relative;

        svg {
          position: absolute;
          width: 40px;
          height: 40px;
          top: 15px;
        }

        input {
          padding-left: 40px;
        }
      }
    }

    input {
      font-family: $font-second;
      font-weight: 400;
      line-height: 100%;
      width: 100%;
      @include b2();
      outline: none;
      background: transparent;
      padding-bottom: 10px;
      border-radius: 0px;

      border: none;
      border-bottom: 1px solid $blue-dark;

      &::placeholder {
        color: $blue-dark;
        opacity: .5;
      }
    }
  }
}

.counter {
  &__num {
    width: 40px;
    height: 40px;
    background: $grey-light;
    border: 1px solid $grey;
    border-radius: 5px;
  }

  button {
    max-width: 26px;
    width: 26px;
    height: 26px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: $black;
    line-height: 24px;
    font-size: 24px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.order {
  @media (max-width: $media-small) {
    &-video {
      &__edit {
        .calculator-block {
          flex-direction: column;
          gap: 10px;

          &__tabs {
            .tabs-box {
              width: 100%;
              flex-direction: column;

              &__tab {
                width: 100%;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  &-category {
    border-radius: 17px;
    border: 1px solid $grey-light;
    width: 100%;

    &.active {
      border: 1px solid $blue;

      .order-category {
        &__block {
          background: $blue;

          * {
            color: $white;
          }
        }
      }
    }

    &__block {
      background: $grey-light;
      padding: 14px 22px;
      border-radius: 16px;
      cursor: pointer;

      img {
        &.open {
          transform: rotateZ(90deg);
        }
      }
    }

    &__info {
      &__title {
        font-size: 18px !important;
      }
    }

    &__tariffs {
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      h4 {
        font-size: 20px !important;
      }
    }
  }

  &-tariff {
    background: $grey-light;
    border-radius: 16px;
    padding: 14px 22px;

    &.active {
      background: $blue;

      .order-tariff {
        &__card {
          span {
            border-bottom: 1px solid $white;
          }
        }

        &-info {
          &__item {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          }
        }
      }

      .tariff-card {
        &__available {
          background: transparent;
          margin-bottom: 0;
          padding: 7px 0;

          img {
            filter: brightness(0) invert(1);
          }

          p {
            @include b6();
          }
        }

        &__advantages {
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          &.mb-6 {
            margin-bottom: 14px !important;
          }
          &__title {
            font-size: 20px !important;
          }

          path {
            fill: #FFFFFF !important;
          }

          img {
            filter: brightness(0) invert(1);
          }
        }

        &__phone {
          &-info {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            img {
              filter: brightness(0) invert(1);
            }
          }
        }
      }

      * {
        color: $white;
      }
    }

    &__card {
      position: relative;
      cursor: pointer;

      span {
        position: absolute;
        bottom: 0px;
        right: 0px;
        border-bottom: 1px solid $black;
        cursor: pointer;
      }
    }

    &-info {
      margin-top: 14px;

      &__item {
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }

      &__available {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.nav {
  &-dropdown {
    position: relative;

    &--light {
      .links {
        a {
          &:hover {
            .link-drop {
              opacity: .8;
            }
          }
        }

        .link-drop {
          background: $white !important;
          color: $blue !important;
        }
      }
    }

    img {
      width: 20px;
    }

    &__button {
      cursor: pointer;
      transition: .5s;

      &:hover {
        opacity: .6;
      }
    }

    .links {
      position: absolute;
      top: 130%;
      width: 200px;
      gap: 5px !important;

      a {
        &:hover {
          border-bottom: none !important;
          .link-drop {
            background: darken($blue, 15);
          }
        }
      }

      .link-drop {
        padding: 6px 10px;
        border-radius: 30px;
        background: $blue;
        transition: .5s;
        color: $white;
      }
    }
  }
}

.stock {
  &-card {
    padding: 140px 0;
    padding-left: 100px;
    position: relative;
    overflow: hidden;
    border-radius: 40px;
    background: #E7EDFB;
    cursor: pointer;


    &.large, &.medium, &.small {
      height: 100%;
      h2 {
        svg {
          width: 18px;
          height: 30px;
        }
        span {
          font-size: 24px !important;
          line-height: 24px;
          height: 24px;
          padding-top: 4px;
        }
      }

      p {
        @include b6();
      }
    }

    &.large {
      padding: 60px 0 !important;
      padding-left: 50px !important;

      .img {
        position: absolute;
        width: 300px !important;
        right: 70px !important;
      }
    }

    &.medium {
      padding: 0 50px;

      .img {
        width: 280px !important;
        right: 20px !important;
      }

      p {
        width: 50%;
      }
    }

    &.small {
      background: $white !important;
      padding: 20px !important;
      width: 100%;
      box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.1);
      border-radius: 30px;

      &:hover {
        .img {
          img {
            transform: scale(1.1);
          }
        }
      }

      .img {
        position: relative !important;
        width: 100% !important;
        height: 300px !important;
        background: #E7EDFB;
        margin-bottom: 20px;
        border-radius: 20px;
        justify-content: center !important;

        img {
          width: 250px !important;
          pointer-events: none;
          transition: .4s;
        }

        label {
          position: absolute;
          margin: 0;
          bottom: 8px;
          left: 8px;
        }
      }

      p {
        width: 100%;
        padding-right: 30px;
      }

      button {
        width: 100%;
        margin: 0;
      }
    }

    .img {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      width: 480px;
      height: 100%;
      //right: 100px;

      img {
        width: 100%;
      }
    }

    h2 {
      align-items: center;
      span {
        @include h5($blue);
        //margin-top: 10px;
        padding-top: 3px !important;
      }
    }

    label {
      height: 33px;
      background: $green;
      border-radius: 35px;
    }
  }
}

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &-complete {
    background: rgba(0, 0, 79, 0.6);

    h3 {
      width: 80% !important;
      font-size: 24px !important;
      text-align: center;
      margin: 0 auto;
    }
  }

  &__dialog {
    padding: 50px 40px;
    background: $blue;
    border: 4px solid $blue-light;
    border-radius: 20px;
    width: 450px;

    * {
      color: $white;
    }

    a {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 20px;
      margin: 0 auto;
      border-radius: 50px;
      border: 1px solid $white;
      text-decoration: none;
      gap: 7.5px;
      @include b5();

      &:hover {
        background: rgba(255, 255, 255, 0.15);
      }

      svg {
        transform: rotateZ(180deg);
        width: 12px;
        height: 7.5px;
      }
    }
  }
}
