@mixin h1($color) {
  font-size: 60px;
  font-weight: $weight-medium;
  letter-spacing: -.04em;
  color: $color;
}

@mixin h2($color) {
  font-size: 50px;
  font-weight: $weight-medium;
  line-height: 110%;
  letter-spacing: -.01em;
  color: $color;
}

@mixin h3($color) {
  font-size: 24px;
  font-weight: $weight-medium;
  line-height: 120%;
  color: $color;
}

@mixin h4($color) {
  font-size: 20px;
  font-weight: $weight-medium;
  line-height: 120%;
  letter-spacing: -.03em;
  color: $color;
}

@mixin h5($color) {
  font-size: 36px;
  font-weight: $weight-medium;
  letter-spacing: -.03em;
  color: $color;
}

@mixin b1() { font-size: 24px; }
@mixin b2() { font-size: 20px; }
@mixin b3() { font-size: 18px; font-weight: $weight-medium; }
@mixin b4() { font-size: 18px; font-weight: $weight-light; }
@mixin b5() { font-size: 16px; font-weight: $weight-medium; }
@mixin b6() { font-size: 16px; }
@mixin b7() { font-size: 14px; }
@mixin b8() { font-size: 14px; font-weight: $weight-medium; }
@mixin b9() { font-size: 18px; }


@mixin clearCard() {
  .img {
    width: 100%;
    min-height: 300px !important;

    &-wrapper {
      height: auto;
    }
  }

  .post-card .content {
    position: relative;
    bottom: 0;
    left: 0;
    max-width: 100%;

    * {
      color: $blue;
    }
  }
}
@mixin fillCard() {
  .post-card {
    .img {
      height: 100%;

      &-wrapper {
        height: 100%;
      }
    }

    .content {
      position: absolute;
      bottom: 50px;
      left: 50px;
      max-width: 70%;

      * {
        color: $white;
      }
    }
  }
}
