@import "_reset";
@import "_variables";
@import "_fonts";
@import "_mixins";
@import "_ui";
@import "_windows";

h1,
h2,
h3,
h4,
h5,
p,
span,
a,
b,
i {
  font-family: $font-main;
}

h1 {
  @include h1($black);

  @media (max-width: $media-desktop-small) {
    @include h5($black);
  }
}
h2 {
  @include h2($black);

  @media (max-width: $media-desktop-small) {
    @include h5($black);
  }
}
h3 {
  @include h3($black);

  @media (max-width: $media-desktop-small) {
    @include h4($black);
  }
}
h4 {
  @include h4($black);

  @media (max-width: $media-desktop-small) {
    @include b8();
  }
}
h5 {
  @include h5($black);
}

.body {
  font-family: $font-second;
  line-height: 120%;
  color: $black;

  &-1 {
    @include b1();

    @media (max-width: $media-desktop-small) {
      @include b9();
    }
  }
  &-2 {
    @include b2();
  }
  &-3 {
    @include b3();

    @media (max-width: $media-desktop-small) {
      @include b8();
    }
  }
  &-4 {
    @include b4();

    @media (max-width: $media-desktop-small) {
      @include b7();
    }
  }
  &-5 {
    @include b5();
  }
  &-6 {
    @include b6();
  }
  &-7 {
    @include b7();
  }
  &-8 {
    @include b8();
  }
  &-9 {
    @include b9();
  }
}

section,
main {
  padding: 80px 0;

  @media (max-width: $media-desktop-small) {
    padding: 120px 0;

    h1 {
      margin-bottom: 20px;

      svg {
        width: 14px !important;
        height: 34px !important;
      }

      span {
        margin-top: 4px !important;
      }
    }

    h2 {
      svg {
        height: 40px !important;
      }

      span {
        padding-top: 7px !important;
      }
    }
  }

  @media (max-width: $media-large) {
    padding: 100px 0;

    h2 {
      svg {
        width: 12px !important;
        height: 30px !important;
      }

      span {
        padding-top: 6px !important;
      }
    }

    h1 {
      svg {
        width: 14px !important;
        height: 34px !important;
      }
    }
  }

  h1 {
    svg {
      width: 18px;
      height: 54px;
    }
    span {
      display: block;
      margin-top: 1px;
      margin-left: 14px;
    }
  }
  h2 {
    svg {
      width: 18px;
      height: 50px;
    }
    span {
      flex: 1;
    }
  }
}

.o {
  &-hidden {
    overflow: hidden;
  }
}

.ta {
  &-c {
    text-align: center;
  }
}

.hidden {
  display: none;
}

.font {
  &-color {
    &-w {
      color: $white;
    }
    &-b {
      color: $black;
    }
    &-bl {
      color: $blue;
    }
    &-bld {
      color: $blue-dark;
    }
    &-g {
      color: $grey;
    }
    &-gd {
      color: $grey-dark;
    }
    &-gr {
      color: $green;
    }
    &-yl {
      color: $yellow;
    }
  }
}

.container {
  width: 1530px;
  margin: 0 auto;
  display: flex;

  @media (max-width: $media-desktop-small) {
    width: 976px;
  }

  @media (max-width: $media-large) {
    width: 728px;
  }

  @media (max-width: $media-medium) {
    width: 100vw;
    padding: 0 20px;
  }

  &-full {
    max-width: 100vw;
  }
}

.hide-scrollbar {
  overflow-y: scroll;
  scrollbar-width: none; /*mozilla*/

  &::-webkit-scrollbar {
    display: none; /*chrome*/
  }
}

.height {
  &-full {
    min-height: 100%;
  }
}

.width {
  &-full {
    min-width: 100%;
  }
}

.flex {
  display: flex;

  &-1 {
    flex: 1;
  }

  &-aifs {
    align-items: flex-start;
  }
  &-aic {
    align-items: center;
  }
  &-aife {
    align-items: flex-end;
  }

  &-jcfs {
    justify-content: flex-start;
  }
  &-jcc {
    justify-content: center;
  }
  &-jcfe {
    justify-content: flex-end;
  }
  &-jcsa {
    justify-content: space-around;
  }
  &-jcsb {
    justify-content: space-between;
  }
}

.column {
  flex-direction: column;
}

@for $i from 0 through 20 {
  .opacity-#{$i} {
    opacity: (5 * $i) / 100;
  }
}

@for $i from 0 through 20 {
  .zi-#{$i} {
    z-index: $i;
  }
}

@for $i from 1 through 10 {
  .fb-#{$i} {
    flex-basis: 100% / $i;
  }
}

@for $i from 1 through 20 {
  .gap-#{$i} {
    gap: 5px * $i;
  }
}

@for $i from 1 through 40 {
  .m {
    &-#{$i} {
      margin: 5px * $i;
    }
    &r-#{$i} {
      margin-right: 5px * $i;
    }
    &l-#{$i} {
      margin-left: 5px * $i;
    }
    &t-#{$i} {
      margin-top: 5px * $i;
    }
    &b-#{$i} {
      margin-bottom: 5px * $i;
    }
  }
}

@for $i from 1 through 50 {
  .p {
    &-#{$i} {
      padding: 5px * $i;
    }
    &r-#{$i} {
      padding-right: 5px * $i;
    }
    &l-#{$i} {
      padding-left: 5px * $i;
    }
    &t-#{$i} {
      padding-top: 5px * $i;
    }
    &b-#{$i} {
      padding-bottom: 5px * $i;
    }
  }
}

.main {
  &-bg {
    background: url("../img/main-bg.png") center no-repeat;
    background-size: cover;
  }
  &-mobile {
    padding-top: 0;
    padding-bottom: 10px;
    a {
      height: 32px;
      font-family: $font-second;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 18px;
      border-radius: 6px;
      padding-top: 7px;
    }

    img {
      width: 100%;
      filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.25));
      border-radius: 20px;
    }

    .labels {
      flex-wrap: wrap;
      gap: 3px;
    }

    h1 {
      font-weight: 500;
      font-size: 28px;
      line-height: 100%;
      letter-spacing: -0.03em;
      color: $blue;
    }

    &-card {
      min-width: 300px;
      //height: 135px;
      padding: 20px;
      border: 1px solid $blue;
      border-radius: 20px;

      h5 {
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: $blue;
        margin-bottom: 2px;
      }

      button {
        width: 100%;
        @include b8();
        font-family: $font-second;
        background: $yellow;
        border-radius: 12px;
        padding: 15px 0;
        border: none;
      }

      &__wrapper {
        display: flex;
        overflow: scroll;
      }
    }
  }
}

.section {
  &__content {
    @media (max-width: $media-desktop-small) {
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.header {
  @media (max-width: $media-desktop-small) {
    .logo {
      margin-right: 60px;
    }

    .top-links {
      gap: 20px;
    }

    .bottom-links {
      .actions {
        gap: 20px;
      }
    }

    nav {
      ul {
        gap: 20px;
      }
    }
  }

  @media (max-width: $media-large) {
    .logo {
      margin-right: 20px;
    }
    &__link {
      @include b7();
    }

    .top-links {
      gap: 10px;

      .link,
      .phone {
        @include b7();
      }
    }

    .bottom-links {
      .actions {
        gap: 10px;
      }
      .link {
        span {
          display: none;
        }
      }
    }
  }

  @media (max-width: $media-medium) {
    .logo {
      margin-right: 30px;
      display: flex;
      align-items: center;

      svg {
        width: 80px;
        height: 41px;
      }
    }

    .header__link {
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      padding-top: 16px;
      margin-bottom: -4px;
    }

    &.mode {
      &-light {
        .mobile__menu {
          svg {
            rect {
              fill: $white;
            }
          }
        }
      }
    }
  }

  @media (max-width: $media-small) {
    .header__link {
      display: none;
    }
  }

  &.mode {
    &-default {
      * {
        color: $blue;
      }

      .header-row {
        border-bottom: 2px solid rgba(11, 79, 214, 0.1);
      }

      ul {
        a {
          &:hover {
            border-bottom: 2px solid $blue;
          }
        }
      }

      .link {
        &:hover {
          border-bottom: 2px solid $blue !important;
        }
      }
    }
  }

  * {
    color: $white;
  }

  .mobile {
    &__menu {
      height: 44px;
      padding: 0 14px;
      border-left: 2px solid rgba(11, 79, 214, 0.1);
      margin-bottom: -2px;
    }

    &-menu {
      position: fixed;
      z-index: 9999;
      background: $white;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      padding-top: 20px;
      transition: 0.5s;
      transform: translateX(100vw);
      overflow: auto;
      padding-bottom: 30px;

      * {
        color: $blue !important;
      }

      @media (max-width: $media-small) {
        &__top {
          margin-top: 20px;

          a {
            height: 29px !important;
          }
        }

        &__nav {
          margin-top: 30px;
          .header-menu {
            ul {
              gap: 16px;
              a {
                font-size: 18px !important;
              }
            }
          }
        }

        &__actions {
          //margin-top: 40px;
          gap: 10px;

          span {
            @include b8();
          }
        }

        &__links {
          .top-links {
            gap: 15px !important;

            .link {
              svg {
                margin-right: 4px;
              }
              span {
                font-size: 14px !important;
              }
            }
          }
        }
      }

      &--open {
        transform: translateX(0);
      }

      &__top {
        a {
          height: 33px;
          border-radius: 100px;
          border: 1px solid $blue;
          padding: 0 20px;
          padding-top: 2px;

          &.active {
            border: none;
            color: $white !important;
            background: $blue;
          }
        }
      }

      &__nav {
        .header-menu {
          ul {
            flex-direction: column;
            align-items: flex-start;

            a {
              @include h3($blue);
            }
          }
        }
      }

      &__actions {
        .link {
          span {
            padding-top: 3px;
          }
        }
      }

      &__links {
        .top-links {
          gap: 20px;

          .link {
            svg {
              margin-right: 4px;
            }
            span {
              padding-top: 3px;
            }
          }
        }
      }
    }
  }

  &-row {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    position: relative;

    .link {
      cursor: pointer;

      span {
        display: block;
        margin-left: 2px;
      }

      svg {
        margin-top: -3px;
      }
    }
  }

  &__link {
    padding-top: 23px;
    cursor: pointer;

    a {
      &:hover {
        border-bottom: none !important;
      }
    }

    &--active {
      margin-bottom: -2px;
      border-bottom: 2px solid $yellow;
    }
  }

  &-menu {
    padding: 14px 0px;

    ul {
      a {
        transition: 0.1s;
        &:hover {
          border-bottom: 2px solid $white;
        }
      }
    }
  }

  .top-links {
    .link {
      transition: 0.1s;
      &:hover {
        border-bottom: 2px solid $white;
      }
    }

    .phone {
      border-bottom: 2px solid $yellow;
    }
  }

  .bottom-links {
    .link {
      span {
        //display: block;
        //margin-left: 2px;
        //margin-top: 3px;
      }
    }
  }
}

footer {
  position: relative;
  background: $blue;
  overflow: hidden;

  @media (max-width: $media-desktop-small) {
    .montain-texture-footer {
      width: 83vw !important;
      left: 200px;
      bottom: -210px !important;
      // z-index: ;
    }

    .container {
      flex-direction: column;

      .mobile-block {
        right: -40%;
        width: 200vw;
      }

      .left {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-height: 457px;
        padding-top: 0;
        padding-bottom: 0;
        z-index: 0;

        .mockup-wrapper {
          height: 453px;
          overflow: hidden;
        }

        .mockup {
          position: relative;
          width: 350px;
          margin-bottom: -300px;

          &__wrapper {
            overflow: hidden;
            max-height: 457px;
          }
        }
      }

      .right {
        margin-top: 20px;
        padding-left: 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        z-index: 100;

        .nav-wrapper {
          margin-top: 20px;
          flex-direction: column-reverse;

          .nav-title {
            font-size: 20px;
            padding-bottom: 0;
          }

          .links-block {
            h4 {
              font-size: 20px;
            }

            &-wrapper {
              justify-content: center;
              row-gap: 10px;
              column-gap: 10px;
              grid-template-columns: repeat(4, 230px);
              grid-template-rows: auto;
              padding: 40px 0;
            }
          }

          &__menu {
            display: grid;
            justify-content: center;
            grid-template-columns: repeat(4, 115px);
            width: 100%;
            row-gap: 20px;
            column-gap: 50px;
            margin: 0 auto;
            padding: 40px 0;

            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          }
        }

        .footer {
          &-description {
            margin-right: 0;
            width: 341px;
            @include b7();
            font-weight: 400;
          }

          &-watermark {
            @include b7();
            font-weight: 400;
          }

          &-bottom {
            margin-top: 20px;
            justify-content: space-between;
            align-self: flex-start;
            width: 660px;

            .footer-bottom-wrapper {
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 40px;

              .socials {
                margin: 0;
              }

              .footer-watermark {
                margin: 0;
                // width: 200px;
              }
            }
          }
        }
      }
    }
    .footer {
      &-bottom {
        .socials {
          margin-bottom: 40px;
        }
      }
    }
  }

  @media (max-width: $media-large) {
    .montain-texture-footer {
      width: 125vw !important;
      left: -200px;
      bottom: -210px !important;
    }

    .container {
      .left {
        max-height: 608px;
        flex-direction: column;
        padding-top: 32px;
      }

      .right {
        .links-block {
          &-wrapper {
            gap: 40px !important;
            grid-template-columns: repeat(2, 230px) !important;
          }
        }
      }
    }

    .footer {
      &-bottom {
        .socials {
          margin-bottom: 20px;
        }
      }
    }
  }

  @media (max-width: $media-medium) {
    .container {
      .left {
        padding-right: 0;
      }

      .right {
        margin-top: 0px;
        padding-top: 20px;
        .nav-wrapper {
          &__menu {
            grid-template-columns: repeat(3, 115px);
            // display: flex;
            // flex-direction: column;
            // gap: 18px;
          }
        }

        .links-block {
          h4 {
            font-size: 18px !important;
          }

          &-wrapper {
            gap: 40px !important;
            grid-template-columns: repeat(2, 170px) !important;
          }
        }
      }
    }

    .montain-texture-footer {
      width: 800px !important;
      bottom: 0px !important;
      left: -0px !important;
      z-index: 1;
    }

    .footer-bottom {
      margin-top: 0 !important;
      width: 500px !important;
    }

    .footer-bottom-wrapper {
      margin-top: 20px !important;
      justify-content: flex-start;

      align-items: flex-start !important;
      flex-direction: column !important;
      gap: 20px !important;
    }

    .footer {
      &-description {
        margin-right: 0 !important;
        font-size: 12px !important;
        width: 120% !important;
      }

      &-watermark {
        font-size: 12px !important;
      }
    }
  }

  @media (max-width: $media-small) {
    .container {
      .left {
        padding-right: 0;
        max-height: 608px;

        .mobile-block-footer {
          flex-direction: column;
          align-items: center;
          width: 100%;
          text-align: center;

          &:last-child {
            gap: 20px;
            margin-top: 20px;
          }

          img {
            max-width: 170px;
          }
        }

        .mockup {
          width: 260px;
          height: 590px;
          margin-bottom: 0;

          &__wrapper {
            overflow: hidden;
            max-height: 457px;
          }
        }
      }

      .right {
        .nav-wrapper {
          &__menu {
            grid-template-columns: repeat(2, 115px);
            gap: 18px;
          }
        }

        .links-block {
          &-wrapper {
            justify-content: flex-start !important;
            gap: 40px !important;
            grid-template-columns: repeat(1, 170px) !important;
          }
        }
      }
    }

    .montain-texture-footer {
      left: -30px !important;
    }

    .footer-bottom-wrapper {
      width: 200px !important;
    }

    .footer {
      &-bottom {
        flex-direction: column;
      }

      &-description {
        width: 56% !important;
        margin-bottom: 20px;
      }

      &-watermark {
        font-size: 12px !important;
      }
    }
  }

  .nav-wrapper {
    &__menu {
      p {
        cursor: pointer;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .nav-item {
      font-size: 14px;
    }
  }

  a {
    transition: 0.5s;

    &:hover {
      text-decoration: underline;
    }
  }

  .container {
    position: relative;
  }

  .mobile-block {
    position: absolute;
    width: 100vw;
    height: 100%;
    background: $black;
    bottom: 0;
    right: 0px;
    z-index: 0;
  }

  .montain-texture-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70vw;
    z-index: 0;

    img {
      width: 100%;
    }
  }

  .left {
    position: relative;
    width: 100%;
    z-index: 2;
    padding-bottom: 195px;
  }

  .right {
    display: flex;
    justify-content: space-between;
    // background: $blue;
    // z-index: 1;
    width: 1800px;
  }

  .footer-bottom {
    width: 440px;
    align-self: center;

    .footer-bottom-wrapper {
      margin-left: 56px;
    }
  }

  .mockup {
    max-width: 350px;
    position: absolute;
    top: 0px;
  }

  .links-block {
    max-height: 160px;
    &-wrapper {
      display: grid;
      row-gap: 40px;
      column-gap: 60px;
      grid-template-columns: repeat(2, 230px);
      grid-template-rows: repeat(2, 160px);
    }

    p {
      cursor: pointer;
    }
  }

  .footer-description {
    margin-right: 90px;
    width: 490px;

    a {
      display: inline !important;
      color: $white;
      text-decoration: underline;
      margin-left: 5px;
    }
  }
}

#main {
  padding-top: 120px;
  padding-bottom: 184px;

  @media (max-width: $media-desktop-small) {
    padding-top: 112px;
    padding-bottom: 178px;

    .container {
      justify-content: space-between;
    }

    .main {
      &__wrapper {
        padding-top: 0;
        padding-right: 0;
      }

      &__description {
        margin-bottom: 20px;
        //padding-right: 130px;
      }

      &__content {
        flex-basis: auto;
      }

      &__slider {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }

  @media (max-width: $media-large) {
    padding-top: 80px;
    padding-bottom: 124px;

    .container {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .main {
        &__description {
          padding-right: 0;
          margin: 0 auto;
          margin-bottom: 20px;
        }

        &__content {
          flex-basis: auto;
          align-items: center;
          margin-bottom: 40px;
        }

        &__wrapper {
          padding-right: 0;
          text-align: center;
        }

        &__mobile-app {
          display: none;
        }

        &__slider {
          .slider {
            margin: 0 auto;
          }

          .main__mobile-app {
            display: block;
            margin: 0 auto;
            margin-top: 40px;
            text-align: center;
          }
        }
      }
    }

    .main {
      &__wrapper {
        button {
          margin: 0 auto;
        }
      }
    }
  }

  .main {
    &__description {
      &-selected-text {
        background: $blue;
        display: inline-flex;
        align-items: center;
        height: 24px;
        padding: 0 2px;

        span {
          display: inline-block;
        }
      }
    }
  }
}

#tariffs {
  @media (max-width: $media-desktop-small) {
    padding-top: 100px;
    padding-bottom: 100px;

    h2 {
      margin-bottom: 40px;
    }
  }

  @media (max-width: $media-large) {
    h2 {
      font-size: 24px !important;
    }
  }

  @media (max-width: $media-medium) {
    padding-top: 10px;

    h2 {
      margin-bottom: 30px;
    }
  }

  @media (max-width: $media-small) {
    .tariffs {
      &-list {
        &__wrapper {
          overflow: visible !important;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  @media (max-width: 1110px) {
    .button-arrow {
      display: none;
    }
  }

  .button {
    &-arrow {
      position: absolute;
      right: -30px;
      z-index: 9999;
    }
  }

  .tariffs {
    &-list {
      display: flex;
      align-items: center;
      position: relative;
      &__wrapper {
        @media (max-width: $media-desktop-small) {
          justify-content: flex-start;
          gap: 20px;
          overflow: scroll;
        }
      }
    }

    &-modal {
      position: fixed;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.5);
      top: 0;
      left: 0;
      z-index: 9999;
      display: flex;
      align-items: center;
      justify-content: center;

      &__block {
        max-width: 700px;
        min-width: 500px;
        padding: 20px;
        background: white;
        border-radius: 18px;
      }
    }
  }

  .additional {
    h2 {
      @include h5($blue);

      span {
        padding-top: 12px;
      }
    }
  }
}

#mobile-app {
  background: $grey-light;
  overflow: hidden;

  @media (max-width: $media-desktop-small) {
    padding-top: 120px;
    padding-bottom: 0;
    max-height: 828px;
  }

  @media (max-width: $media-medium) {
    max-height: 910px;
  }

  .container {
    position: relative;

    @media (max-width: $media-desktop-small) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      p {
        padding: 0;
        width: 70%;
      }

      .mockup {
        position: relative;
        right: 0;
        max-width: 490px;
        margin-top: 40px;
      }
    }

    @media (max-width: $media-medium) {
      p {
        padding: 0;
        width: 100%;
      }

      .buttons-wrapper {
        flex-wrap: wrap;
        justify-content: center;
      }

      .mockup {
        position: relative;
        right: 0;
        max-width: 100%;
        margin-top: 40px;
      }
    }
  }

  .mockup {
    position: absolute;
    right: 50px;
  }
}

#tv {
  background: $blue;
  position: relative;
  overflow: hidden;

  @media (max-width: $media-desktop-small) {
    padding-bottom: 0;
    padding-top: 120px;

    .container {
      flex-direction: column;
      align-items: center;

      .section__content {
        padding-right: 0;
        width: 70%;
        text-align: center;
        margin-bottom: 60px;
      }
    }

    .mockup {
      display: none;
    }

    .adapt {
      display: block !important;
      margin-bottom: -280px;
    }
  }

  @media (max-width: $media-medium) {
    .container {
      .section__content {
        width: 100%;
      }
    }

    .adapt {
      width: 100%;
      margin-bottom: -32%;
    }
  }

  .adapt {
    display: none;
  }

  .mockup {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 48.18vw;
  }
}

#video-surveillance {
  background: $grey-light;
  position: relative;
  overflow: hidden;

  @media (max-width: $media-desktop-small) {
    padding-bottom: 0;
    padding-top: 120px;

    .container {
      flex-direction: column;
      align-items: center;

      .section__content {
        padding-right: 0;
        width: 70%;
        text-align: center;
        margin-bottom: 60px;
      }
    }

    .mockup {
      display: none;
    }

    .adapt {
      display: block !important;
    }
  }

  @media (max-width: $media-medium) {
    .container {
      .section__content {
        width: 100%;
      }
    }

    .adapt {
      width: 100%;
    }
  }

  .adapt {
    display: none;
  }

  .mockup {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 48.18vw;
  }
}

#events {
  @media (max-width: $media-desktop-small) {
    padding-top: 100px;
    padding-bottom: 100px;

    h2 {
      margin-bottom: 60px;
    }
  }

  @media (max-width: $media-large) {
    h2 {
      margin-bottom: 40px;
      @include h3($blue);
    }
  }

  @media (max-width: $media-medium) {
    .post-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .events {
      &__more {
        margin-top: 20px;
        justify-content: center;
      }
    }

    h2 {
      span {
        padding-top: 5px !important;
      }
    }
  }

  .events {
    &__more {
      span {
        display: block;
      }
      svg {
        &.arrow {
          width: 16px;
          height: 10px;
        }
      }
    }
  }
}

#banner {
  padding: 112px 0;
  position: relative;
  overflow: hidden;
  background: #f1f5f9;

  @media (max-width: $media-desktop-small) {
    .banner {
      img {
        max-width: 1440px !important;
      }

      h1 {
        font-size: 24px !important;

        span {
          padding-top: 5px !important;
        }
      }
    }

    &.rate-page {
      padding: 50px 0;
    }

    &.iptv-page {
      padding: 106px 0 !important;
      h1 {
        margin-bottom: 15px;
      }
      .button {
        margin-top: 30px;
      }
    }
  }

  @media (max-width: $media-large) {
    &.iptv-page {
      padding: 72px 0 !important;
    }
    .banner {
      img {
        max-width: 1024px !important;
      }
    }
  }

  @media (max-width: $media-medium) {
    &.iptv-page {
      padding-top: 0 !important;
    }

    background: #ffffff;
    padding-top: 0 !important;
    .banner {
      flex-direction: column;
      img {
        position: relative !important;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: $media-small) {
    .banner {
      h1 {
        span {
          //margin-top: 0 !important;
        }
      }

      &-content {
        padding-left: 0;
        width: 100%;

        &__block {
          max-width: 250px;
          margin-right: auto;
          margin-left: auto;
        }

        .button {
          width: 100%;
        }
      }
    }
  }
  .banner {
    display: flex;
    justify-content: center;

    label {
      height: 33px;
      background: $green;
      border-radius: 35px;
    }

    img {
      position: absolute;
      max-width: 1980px;
      bottom: 0;
      z-index: 2;
      pointer-events: none;
    }
    h1 {
      z-index: 3;
      @include h5($blue);

      span {
        padding-top: 13px;
      }
    }
    &-content {
      z-index: 3;
      &__block {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        width: 307px;

        svg {
          width: 28px;
          height: 28px;
        }

        h4 {
          font-weight: 400;
          font-size: 30px;
          line-height: 120%;
          letter-spacing: -0.02em;
        }
      }
    }
  }

  &.rate-page {
    .button {
      margin-top: 20px !important;
    }
  }

  &.iptv-page {
    padding: 167px 0;
    h1 {
      margin-bottom: 20px !important;
    }
    .button {
      margin-top: 30px;
    }
    p {
      opacity: 0.5 !important;
    }
  }

  &.stock-page {
    padding-top: 0px;
    padding-bottom: 0px;

    @media (max-width: $media-desktop-small) {
      label {
        display: none;
      }
      .banner {
        .container {
          padding: 107px 0;
        }
      }
    }

    @media (max-width: $media-large) {
      .banner {
        .container {
          padding: 72px 0 !important;
        }
      }
    }

    @media (max-width: $media-medium) {
      padding-bottom: 0px;
      .banner {
        .container {
          padding: 0 20px !important;
        }
        .stock {
          display: none;
        }
        &-img {
          display: block !important;

          &__wrapper {
            padding: 20px 0;
            background: #e7edfb;
            margin-bottom: 20px;

            img {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .banner {
      .container {
        padding: 140px 0;
        position: relative;

        img {
          height: 85%;
          bottom: 7.5%;
          right: 80px;
        }
      }

      &-img {
        display: none;
      }
    }

    h1 {
      margin-bottom: 12px;
      span {
        margin-top: 2px;
      }
    }
    .button {
      margin-top: 30px;
    }
    p {
      opacity: 0.5 !important;
    }
  }
}

#info {
  @media (max-width: $media-desktop-small) {
    .info {
      flex-direction: row-reverse;

      h2 {
        font-size: 24px !important;
      }

      &-content {
        padding-right: 0;
        padding-left: 40px;

        &__text {
          @include b6();
        }
      }

      .img {
        min-width: 450px !important;
        min-height: 430px;
      }
    }
  }

  @media (max-width: $media-large) {
    .info {
      .img {
        min-width: 297px !important;
      }
    }
  }

  @media (max-width: $media-medium) {
    padding-top: 50px;
    .info {
      flex-direction: column-reverse;

      &-content {
        padding-left: 0;
      }

      .img {
        min-width: 100% !important;
        min-height: 208px !important;
        margin-bottom: 30px;
      }
    }
  }

  .info {
    .img {
      min-width: 600px;
      min-height: 470px;
      border-radius: 30px;
      background-size: cover;
      background-position: center;
    }

    h2 {
      @include h5($blue);
    }

    ul {
      li {
        background: $grey-light;
        border-radius: 50px;

        svg {
          width: 24px;
        }
      }
    }
  }
}

#advantages {
  position: relative;
  background: $grey-light;
  overflow: hidden;

  @media (max-width: $media-large) {
    padding: 50px 0;
    .img {
      top: auto !important;
      bottom: -380px !important;
    }

    h2 {
      @include h3($black);
      margin-bottom: 40px;
    }

    .advantages {
      &-content {
        flex-direction: column;
        gap: 40px;
        align-items: center !important;

        &__block {
          flex-direction: row;
          gap: 20px;
          //flex: auto !important;
          //flex-basis: 100% !important;
          &__content {
            max-width: 270px;

            h3 {
              @include b3();
            }

            p {
              @include b9();
            }

            * {
              text-align: left;
            }
          }
        }
      }
    }
  }

  @media (max-width: $media-small) {
    .img {
      bottom: -50px !important;
    }

    .advantages {
      &-content {
        &__block {
          flex-direction: column;
          &__content {
            * {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .img {
    @media (min-width: $media-desktop) {
      min-width: 100vw;
    }
    position: absolute;
    min-width: 1920px;
    height: 880px;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
  }

  &.bg-light {
    background: $white;
    .img {
      display: none;
    }
  }

  .advantages {
    &-content {
      align-items: flex-start;
      width: 100%;

      &__block {
        flex: 1;

        h2 {
          min-height: 58px;
        }
      }

      &__icon {
        width: 100px;
        height: 100px;
        border-radius: 100px;

        span {
          color: $blue;
          font-family: $font-main;
          font-style: normal;
          font-weight: 500;
          font-size: 70px;
          text-align: center;
          letter-spacing: -0.04em;
        }
      }
    }
  }
}

#support {
  padding-top: 100px;
  padding-bottom: 80px;

  @media (max-width: $media-desktop-small) {
    padding-top: 60px;
    .support {
      flex-direction: column;

      h1 {
        span {
          font-size: 24px !important;
          padding-top: 6px !important;
        }
      }

      &-info {
        &__buttons {
          flex-direction: row;
          margin-bottom: 60px;
        }
      }

      &-requisites {
        margin-bottom: 60px;
      }
    }
  }

  @media (max-width: $media-large) {
    .support {
      &-info {
        width: 100%;
        &__content {
          width: 100%;
        }

        &__buttons {
          flex-direction: column;
          margin-bottom: 60px;

          button {
            width: 100% !important;
          }
        }
      }
    }
  }

  @media (max-width: $media-medium) {
    .support {
      &-requisites {
        ul {
          li {
            flex-direction: column;

            &:last-child {
              border-bottom: 1px solid #4b4b4b !important;
              padding-bottom: 20px !important;
            }

            p {
              &:first-child {
                @include b7();
                opacity: 0.6;
                color: $grey-dark;
                margin-bottom: 4px;
              }
            }
          }
        }
      }

      button {
        width: 100% !important;
      }
    }
  }

  .support {
    h1 {
      span {
        @include h5($blue);
        display: flex;
        align-items: center;
        padding-top: 15px;
        margin-bottom: 20px;
      }
    }

    button {
      width: 400px;
    }

    &-requisites {
      ul {
        li {
          border-bottom: 1px solid #4b4b4b;

          &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

#addresses {
  @media (max-width: $media-desktop-small) {
    padding-top: 20px;
    padding-bottom: 100px;

    .addresses {
      height: auto !important;
      flex-direction: column;

      &-wrapper {
        width: 100% !important;
        height: 511px;

        h4 {
          font-size: 20px;
        }
      }

      .map {
        width: 100%;
        min-height: 511px;
      }
    }
  }

  //@media (max-width: $media-desktop-small)

  .addresses {
    height: 511px;

    &-wrapper {
      width: 558px;

      border: 2px solid $grey;
      border-radius: 20px;
    }

    h3 {
      font-size: 24px;
      span {
        padding-top: 6px;
      }
    }

    &-list {
      overflow-y: scroll;
      scrollbar-width: thin;
      scrollbar-color: $yellow $grey-light;
      padding: 0 30px;
      width: 100%;

      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: $grey-light;
        border-radius: 20px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $yellow;
        border-radius: 20px;
        border: 2px solid $grey-light;
      }

      &__block {
        border-bottom: 1px solid $grey;
        cursor: pointer;

        &:hover {
          h4 {
            text-decoration: underline;
          }
        }

        h4 {
          position: relative;
          display: flex;
          align-items: center;

          span {
            position: absolute;
            left: -30px;
            margin-top: 4px;
          }
        }

        &__time {
          span {
            display: block;

            &:first-child {
              width: 60px;
              margin-right: 8px;
            }
          }
        }
      }
    }

    .map {
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center !important;
      border-radius: 20px;
      overflow: hidden;

      .ymaps-2-1-79 {
        &-ground-pane {
          //filter: sepia(9%) hue-rotate(155deg) saturate(240%);
        }
      }
    }
  }
}

#news {
  &-list {
    padding-top: 100px;
    padding-bottom: 70px;

    @media (max-width: $media-desktop-small) {
      padding-bottom: 30px;
      padding-top: 60px;

      &.news-other {
        padding-bottom: 100px;
      }

      h2 {
        margin-bottom: 20px !important;

        span {
          font-size: 24px !important;
        }
      }

      .filters {
        margin-bottom: 20px;
      }
    }

    @media (max-width: $media-medium) {
      .post {
        &-card {
          &__wrapper {
            flex-basis: 100% !important;

            &:nth-child(3) {
              flex-basis: 100% !important;
            }
          }
        }
      }
    }

    &.news {
      &-other {
        padding-top: 30px;

        span {
          padding-bottom: 4px;
        }
      }
    }

    h2 {
      span {
        @include h5($blue);
      }
    }
  }

  &-item {
    &-banner {
      padding: 60px 0;

      @media (max-width: $media-desktop-small) {
        .news {
          &-item {
            &-banner {
              padding: 40px 30px !important;
              h1 {
                max-width: 100% !important;
                span {
                  font-size: 24px !important;
                  padding-top: 0px !important;
                  margin-top: 10px !important;
                }
              }
            }
          }
        }
      }

      @media (max-width: $media-medium) {
        padding: 60px 20px;
        padding-bottom: 10px !important;

        .news {
          &-item {
            &-banner {
              width: 100% !important;
              height: 300px !important;
              padding: 30px 20px !important;

              .desktop {
                display: none !important;
              }

              &.mobile {
                display: flex !important;
                height: auto !important;
                padding: 0 !important;
                margin-top: 10px;

                h1 {
                  display: none;
                }
              }
            }
          }
        }
      }

      @media (max-width: $media-small) {
        .news {
          &-item {
            &-banner {
              height: 200px !important;

              &.mobile {
                h1 {
                  display: flex !important;
                  margin-bottom: 20px !important;
                  margin-top: 10px;

                  span {
                    color: $blue;
                  }
                }
              }

              h1 {
                display: none;
              }
            }
          }
        }
      }

      .news {
        &-item {
          &-banner {
            padding: 50px;
            height: 600px;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 20px;
            justify-content: flex-end;
            align-items: flex-start;

            &.mobile {
              display: none;
            }

            h1 {
              max-width: 60%;
              margin-bottom: 0 !important;
              span {
                @include h5($white);
                padding-top: 15px;
                flex: 1;
              }
            }

            label {
              height: 37px;
              background: $blue;
              border-radius: 50px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }

    &-content {
      padding-top: 0;

      @media (max-width: $media-desktop-small) {
        .news-item-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          &__html {
            margin-left: 0 !important;

            h5 {
              font-size: 24px !important;
            }
          }
        }
      }

      @media (max-width: $media-large) {
        .news-item-content {
          &__html {
            width: 600px !important;
          }
        }
      }

      @media (max-width: $media-medium) {
        .news-item-content {
          &__html {
            width: 100% !important;
          }
        }
      }

      .news-item-content {
        &__html {
          width: 800px;
          margin-left: 80px;

          img {
            max-width: 100%;
            border-radius: 20px;
          }

          h3 {
            margin-bottom: 30px;
            @include h5($black);
          }

          p {
            font-family: $font-second;
            line-height: 120%;
            color: $grey-dark;
            margin-bottom: 10px;

            @include b9();
          }
        }
      }
    }
  }
}

#modal {
  &-order {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999999;
    background: $blue;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;

    @media (max-width: $media-desktop-small) {
      background: $white;
      align-items: flex-start;

      .modal-order {
        padding: 40px !important;
        flex-direction: column-reverse;

        .tariff {
          &-total {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding-left: 0;

            .price {
              &:first-child {
                margin-top: 0;
              }
            }
          }

          &-card {
            &__info {
              h3 {
                margin-bottom: 8px;
              }
            }

            &__advantages {
              margin-bottom: 0;

              &.mb-6 {
                margin-bottom: 20px;
              }
            }
          }

          &-info {
            width: 100%;
            margin-left: 0;
          }
        }

        .form {
          margin-top: 60px;
          padding-right: 0;
          border-right: none !important;
        }
      }
    }

    @media (max-width: $media-medium) {
      .modal-order {
        padding: 20px !important;
        padding-bottom: 130px !important;

        .tariff {
          &-total {
            flex-direction: column;
          }
          &-price {
            width: 100%;
            padding-top: 20px;

            .price {
              width: 100%;
            }
          }
        }

        .form {
          &-row {
            flex-direction: column;
          }
        }
      }
    }

    @media (max-width: $media-small) {
      .modal-order {
        h5 {
          svg {
            height: 30px !important;
          }
          span {
            @include h3($blue);
            padding-top: 5px !important;
          }
        }

        &__send {
          flex-direction: column-reverse;
          gap: 20px;
          align-items: flex-start;

          .ml-8 {
            margin-left: 0 !important;
            width: 80vw;
          }
        }

        .tariff {
          &-info {
            min-width: 100% !important;
          }
        }

        .form {
          &-radio {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px !important;
          }

          .checkbox {
            width: 100%;
          }

          button {
            width: 100%;
          }
        }
      }
    }

    .modal-order {
      background: $white;
      padding: 80px 100px;
      border-radius: 60px;

      .form {
        flex: 1;
        border-right: 2px solid $grey;

        .price-wrapper {
          p {
            padding: 8px 16px;
            background: $grey-light;
            border-radius: 100px;

            &.total {
              background: $blue;
            }
          }
        }
      }

      .tariff {
        &-total {
          margin: 0;
        }

        &-info {
          min-width: 445px;
          max-height: 70vh;
          padding-right: 15px;
          overflow-y: auto;
          scrollbar-width: thin;
          scrollbar-color: $yellow $grey-light;

          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-track {
            background: $grey-light;
            border-radius: 20px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $yellow;
            border-radius: 20px;
            border: 2px solid $grey-light;
          }
        }
      }

      .price {
        background: $grey-light;
        border-radius: 20px;
        width: 307px;

        &__total {
          background: $blue;

          * {
            color: $white;
          }

          h4 {
            border-bottom-color: $white !important;
          }
        }

        h4 {
          border-bottom: 1px solid $blue-dark;
        }
      }

      .tariff-card__info {
        h3 {
          max-width: 80%;
        }
      }

      h5 {
        margin-bottom: 64px;
        svg {
          width: 18px;
          height: 40px;
        }

        span {
          padding-top: 8px;
        }
      }

      .radio {
        width: auto;
      }

      .checkbox {
        width: 60%;

        label {
          @include b7();
          color: rgba(0, 0, 0, 0.6);
          align-items: flex-start;

          &::before {
            margin-top: 0;
          }

          a {
            display: inline;
            color: $blue;
          }
        }
      }
    }
  }

  &-search {
    position: fixed;
    padding-top: 220px;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 4;
    background: $blue;
    transition: 0.3s;

    &.hidden-search {
      top: -100vh;
    }

    &.search-page {
      height: 80vh;
    }

    @media (max-width: $media-desktop-small) {
      .input-text {
        svg {
          bottom: 10px;
          top: auto;
        }
        input {
          border-bottom: 2px solid $white;
          font-size: 24px !important;

          &::placeholder {
            font-size: 24px !important;
          }
        }
      }
    }

    @media (max-width: $media-medium) {
      padding-top: 110px;
    }

    .modal-search {
      height: 100%;
      padding-bottom: 30px;
      &__wrapper {
        overflow: hidden;
        align-self: stretch;
        max-width: 1140px;
      }

      .button-back {
        border: 1px solid $white;
        gap: 10px;

        svg {
          width: 14px;
          height: 14px;

          path {
            stroke: $white;
          }
        }

        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
      }

      .input-text {
        opacity: 0.6;

        svg {
          path {
            fill: $white;
          }
        }
        input {
          border-bottom: 2px solid $white;
          @include h5($white);

          &::placeholder {
            @include h5($white);
          }
        }
      }

      &__match {
        max-width: 100%;
        &-list {
          overflow: auto;
        }
        &-block {
          background: rgba(255, 255, 255, 0.2);
          border-radius: 20px;
          cursor: pointer;

          &:hover {
            background: rgba(255, 255, 255, 0.3);
          }

          &__content {
            h4 {
              @include h4($white);
            }
          }
        }
      }
    }
  }
}

.info-stock {
  @media (max-width: $media-large) {
    .info {
      .img {
        min-height: 300px !important;
      }
    }
  }

  @media (max-width: $media-medium) {
    padding-top: 50px;
    .info {
      flex-direction: column-reverse !important;

      &-content {
        padding-left: 0 !important;
        margin-top: 0px;

        h2 {
          margin-bottom: 20px;
        }
      }
    }
  }

  .info {
    flex-direction: row-reverse;
    justify-content: flex-end;
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 637px;
      padding-left: 80px;
      padding-right: 0;
    }

    .img {
      min-width: 760px !important;
    }
  }
}

.movie {
  @media (max-width: $media-desktop-small) {
    &-sm {
      .advantages {
        &-content {
          &__block {
            &__content {
              max-width: 270px !important;
            }
          }
        }
      }
    }
    &-kion {
      .advantages {
        &-content {
          &__icon {
            span {
              font-size: 50px !important;
            }
          }
        }
      }
    }
  }

  @media (max-width: $media-large) {
    &-sm {
      .advantages {
        &-content {
          align-items: flex-start;
          &__block {
            &__content {
              max-width: 510px !important;
            }
          }
        }
      }
    }
    &-kion {
      .advantages {
        &-content {
          align-items: center;
          &__block {
            max-width: 400px;
            display: grid;
            grid-template-columns: 108px 1fr;
          }
        }
      }

      h2 {
        display: block !important;
      }
    }
  }

  @media (max-width: $media-medium) {
    &-info,
    &-info-kion {
      padding-top: 80px;
      .info {
        flex-direction: column-reverse !important;
      }

      .img {
        min-height: 300px !important;
        margin-bottom: 40px;
      }
    }
    &-kion {
      .advantages {
        &-content {
          align-items: center;
          &__block {
            max-width: 400px;
            display: grid;
            grid-template-columns: 270px;
            grid-template-rows: 50px auto;

            &__content {
              h3 {
                text-align: center !important;
              }
            }
          }

          &__icon {
            height: 100% !important;
          }
        }
      }
    }
  }

  @media (max-width: $media-small) {
    &-sm {
      .advantages {
        &-content {
          align-items: center;
        }
      }
    }
  }

  &-banner {
    //width: 100vw;
    width: 100%;
    pointer-events: none;
  }

  &-sm {
    h2 {
      display: none;
    }
    .img {
      display: none;
    }
    .advantages {
      &-content {
        &__block {
          &__content {
            max-width: 470px;
          }
        }
      }
    }
  }

  &-info {
    .img {
      background-size: 100% !important;
      background-repeat: no-repeat;
      background-position-y: 0 !important;
    }

    &-kion {
      background-size: 100% !important;
      background-repeat: no-repeat;
      background-position-y: center !important;
    }
  }

  &-kion {
    .advantages {
      &-content {
        &__icon {
          width: 100% !important;
          span {
            //font-size: 50px !important;
            //width: 100%;
          }
        }
      }
    }
  }
}

#calculator {
  padding: 0;

  @media (max-width: $media-desktop-small) {
    .calculator {
      flex-direction: column;
      &-wrapper {
        h3 {
          font-size: 24px;
        }

        &:first-child {
          padding-right: 0 !important;
          border-right: none !important;
          border-bottom: 2px solid $grey;
          padding-bottom: 60px;
          margin-bottom: 60px;
        }

        &:last-child {
          width: 100% !important;
          gap: 25px;
        }

        .price {
          p {
            &:first-child {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  @media (max-width: $media-large) and (min-width: $media-small) {
    .calculator {
      padding: 40px !important;
    }
  }

  @media (max-width: $media-medium) {
    padding: 0 20px;

    .calculator {
      width: 100%;
      border-radius: 30px !important;

      &-block {
        width: 100%;
        justify-content: space-between;

        &__tabs {
          align-items: flex-start;
        }

        .tabs-box {
          width: 130px;

          &__tab {
            width: 100%;
            text-align: center;
          }
        }
      }

      &-wrapper {
        &__cameras {
          width: 100% !important;
        }

        &:first-child {
          padding-bottom: 40px;
          margin-bottom: 40px;
        }
      }
    }
  }

  @media (max-width: $media-small) {
    .calculator {
      padding: 20px !important;

      &-block {
        flex-wrap: wrap;
        gap: 14px;

        .tabs-box {
          width: 100%;

          &__tab {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  .calculator {
    padding: 60px;
    border: 2px solid $blue;
    border-radius: 60px;

    &-block {
      padding: 14px 20px;
      border: 2px solid rgba(11, 79, 214, 0.1);
      border-radius: 20px;
    }

    &-wrapper {
      &:first-child {
        border-right: 2px solid $grey;
        padding-right: 176px;
      }
      &:last-child {
        width: 540px;

        .price {
          width: 100%;
          padding: 20px 30px;
          background: $grey-light;
          border-radius: 20px;

          &-total {
            background: $blue;

            p {
              color: $white;
            }
          }

          p {
            display: flex;
            align-items: center;
          }
        }
      }
      &__cameras {
        width: 400px;

        .calculator-block {
          width: 100%;
        }
      }
    }
  }
}

.centered {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: -60px;

  a {
    text-decoration: none;
    color: inherit;

    button {
      font-size: 16px;
      height: 40px;
    }
  }
}
