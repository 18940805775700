.windows {
  @media (max-width: $media-desktop-small) {
    section {
      h1 {
        span {
          margin-top: -6px !important;
        }
      }

      h2 {
        span {
          padding-top: 3px !important;
        }
      }
    }

    .main {
      &__description {
        &-selected-text {
          height: 24px !important;
        }
      }
    }

    #modal {
      &-search {
        .modal {
          &-search {
            svg {
              top: 12px !important;
            }
          }
        }
      }
    }

    #support {
      .support {
        h1 {
          span {
            padding-top: 9px !important;
          }
        }
      }
    }

    .news {
      h1 {
        span {
          padding-top: 0px !important;
          margin-top: 4px !important;
        }
      }
    }
  }

  @media (min-width: $media-desktop-small) {
    section {
      &#support {
        h1 {
          span {
            margin-top: -7px !important;
          }
        }
      }

      h1 {
        span {
          margin-top: -12px !important;
        }
      }

      h2 {
        span {
          padding-top: 0px !important;
        }
      }
    }

    #modal {
      &-search {
        .modal {
          &-search {
            svg {
              top: 23px !important;
            }
          }
        }
      }
    }
  }

  .button {
    span {
      padding-bottom: 1px;
      &.wrapper-arrow-btn {
        margin-top: 0 !important;
        padding-bottom: 0;
      }
      span {
        margin-top: -8px !important;
        padding-bottom: 0;
        //padding-top: 1px;

        svg {
          margin-top: 3px;
        }
      }
    }

    .news {
      h1 {
        span {
          padding-top: 14px !important;
        }
      }
    }
  }

  h1, h2, h3, h4, h5, p, span, a, b, i, li {
    margin-top: -5px !important;
  }

  .no-mt {
    margin-top: 0 !important;
  }

  .main {
    &__description {
      span {
        //margin-top: 0 !important;
      }
      &-selected-text {
        height: 20px !important;

        span {
          margin-top: -9px !important;
        }
      }
    }
  }

  #banner {
    .banner {
      h1 {
        span {
          padding-top: 12px !important;
        }
      }
    }
  }

  #tariffs {
    .additional {
      h2 {
        span {
          padding-top: 8px !important;
        }
      }
    }
  }

  .slider-control {
    &__progress-bar {
      span {
        margin-top: 0 !important;
      }
    }
  }

  .tariff {
    &-card {
      &__advantages {
        &__content {
          margin-top: 0 !important;
        }
      }

      .price {
        &-old {
          &::after {
            margin-top: 4px !important;
          }
        }
      }

      .currency {
        margin-top: 0 !important;
      }
    }
  }

  .tabs {
    &-box {
      &__tab {
        span {
          display: block;
          margin-top: -3px !important;
        }
      }
    }
  }

  #support {
    .support-requisites {
      ul {
        li {
          margin-top: 0 !important;
        }
      }
    }
  }

  #addresses {
    .addresses {
      &-list {
        &__block {
          h4 {
            span {
              margin-top: 8px !important;
            }
          }

          &__time {
            margin-top: 5px !important;
          }
        }
      }
    }
  }

  #modal {
    &-order {
      .modal {
        &-order {
          p {
            margin-top: auto !important;
          }

          h5 {
            span {
              padding-top: 5px !important;
            }
          }

          .order {
            &-category {
              &__block {
                margin-top: -1px;
              }
              &__tariffs {
                h4 {
                  &.mt-8 {
                    margin-top: 40px !important;
                  }
                }
              }
              &__info {
                &__title {
                  padding-bottom: 5px;
                }
              }
            }
          }

          p {
            &.mt-8 {
              margin-top: 40px !important;
            }
          }
        }
      }
    }

    &-search {
      .modal {
        &-search {
          a {
            margin-top: 0 !important;
          }

          &__match-block__content {
            p {
              margin-top: 6px !important;
            }
          }
        }
      }
    }
  }

  .radio, .checkbox, .counter__num {
    span {
      margin-top: -3px !important;
    }
  }
}
